/* eslint-disable @typescript-eslint/ban-types */
import * as Yup from 'yup';
import ipRegex from 'ip-regex';
import Lazy from 'yup/lib/Lazy';
import { AnySchema } from 'yup';
import _ from 'lodash';
import { AnyObject } from 'yup/lib/types';

export {
    default as ContactFormSchema,
    init as ContactFormSchemaInit,
} from './ContactForm';

export {
    default as UserLoginResetSchema,
    init as UserLoginResetSchemaInit,
} from './UserLoginReset';

export { default as LoginSchema, init as LoginSchemaInit } from './Login';
export {
    default as PasswordResetSchema,
    init as PasswordResetSchemaInit,
} from './PasswordReset';

export {
    default as PasswordChangeSchema,
    init as PasswordChangeSchemaInit,
} from './PasswordChange';

export {
    default as EmailChangeSchema,
    init as EmailChangeSchemaInit,
} from './EmailChange';

export { default as ProfileSchema, init as ProfileSchemaInit } from './Profile';

export {
    default as RegistrationSchema,
    init as RegistrationSchemaInit,
} from './Registration';

export {
    default as MicroKeywordsSchema,
    init as MicroKeywordsSchemaInit,
} from './MicroKeywords';

export {
    default as MacroProjectsSchema,
    init as MacroProjectsSchemaInit,
} from './MacroProjects';

export type SchemaType = {
    [key: string]:
    | Yup.StringSchema<string | undefined, object>
    | Yup.NumberSchema<number | undefined, object>
    | Yup.BooleanSchema<boolean | null | undefined, object>
    | Yup.ArraySchema<AnySchema | Lazy<any, any>, object>
};

Yup.addMethod(Yup.string, 'ipv4', function (message) {
    return this.test('ipv4', message, function (value) {
        const { path, createError } = this;
        if (typeof value === 'undefined' || value === null) return true;
        return (
            ipRegex.v4({ exact: true, includeBoundaries: true }).test(value) ||
            createError({ path, message: message || 'Invalid IPv4 address' })
        );
    });
});

Yup.addMethod(Yup.string, 'ipv6', function (message) {
    return this.test('ipv6', message, function (value) {
        const { path, createError } = this;
        if (typeof value === 'undefined' || value === null) return true;
        return (
            ipRegex.v6({ exact: true, includeBoundaries: true }).test(value) ||
            createError({ path, message: message || 'Invalid IPv6 address' })
        );
    });
});

Yup.addMethod(Yup.string, 'ip', function (message) {
    return this.test('ip', message, function (value) {
        const { path, createError } = this;
        if (typeof value === 'undefined' || value === null) return true;
        return (
            ipRegex({ exact: true, includeBoundaries: true }).test(value) ||
            createError({ path, message: message || 'Invalid IP address' })
        );
    });
});

Yup.addMethod(Yup.array, 'uniqueProperty', function (propertyPath, message) {
    return this.test('unique', '', function (list) {
        const errors: Yup.ValidationError[] = [];

        if (list) {
            // We'll track the seen values and the indices where duplicates are found
            const seenValues = new Map();

            list.forEach((item, index) => {
                const propertyValue = _.get(item, propertyPath);

                // Skip undefined or null property values
                if (propertyValue == null) return;

                // Check if the value has been seen before
                if (seenValues.has(propertyValue)) {
                    // Update the index of the last duplicate occurrence
                    seenValues.set(propertyValue, index);
                } else {
                    // Otherwise, store the index of the first occurrence
                    seenValues.set(propertyValue, -1);
                }
            });

            // After scanning all items, now check for duplicates and mark only the last occurrence
            seenValues.forEach((lastIndex, _value) => {
                if (lastIndex !== -1) {
                    errors.push(
                        this.createError({
                            path: `${this.path}[${lastIndex}].${propertyPath}`,
                            message: message || "Duplicate found"
                        })
                    );
                }
            });
        }

        if (!_.isEmpty(errors)) {
            throw new Yup.ValidationError(errors);
        }

        return true;
    });
});

const uniquePropertyTest = function (
    this: Yup.TestContext<AnyObject>,
    testedObject,
    propertyName,
    message,
    compareFunction: ((objectValue: any, testedObjectValue: any) => boolean) | null = null,
) {
    if (
        this.parent
            // Exclude the same object (by reference)
            .filter(object => object !== testedObject)

            // Check for property match among some of the other objects
            .some(object => {
                const objectValue = _.get(object, propertyName);
                const testedObjectValue = _.get(testedObject, propertyName);

                return compareFunction
                    ? compareFunction(objectValue, testedObjectValue)
                    : objectValue === testedObjectValue;
            })
    ) {
        throw this.createError({
            path: `${this.path}.${propertyName}`,
            message,
        });
    }

    return true;
};

Yup.addMethod(
    Yup.object,
    'uniqueProperty',
    function (propertyName, message, compareFunction = null) {
        return this.test('unique', message, function (value) {
            return uniquePropertyTest.call(
                this,
                value,
                propertyName,
                message,
                compareFunction,
            );
        });
    },
);

Yup.addMethod(Yup.object, 'uniqueProperties', function (properties) {
    return this.test('unique', '', function (value) {
        const errors = properties
            .map(([propertyName, message, compareFunction = null]) => {
                try {
                    return uniquePropertyTest.call(
                        this,
                        value,
                        propertyName,
                        message,
                        compareFunction,
                    );
                } catch (error) {
                    return error;
                }
            })
            .filter(error => error instanceof Yup.ValidationError);

        if (!_.isEmpty(errors)) {
            throw new Yup.ValidationError(errors);
        }

        return true;
    });
});

export { Yup };
